<template>
  <div v-editable="blok">
    <div :class="classes">
      <div
        v-if="layout === 'feature-numbers-grid'"
        class="w-layout-grid features-number-grid"
      >
        <template
          v-for="(itemBlok, i) in blok.items"
          :key="itemBlok._uid || i"
          :blok="{
            ...itemBlok,
            ...utils.lottieProps(blok, true),
          }"
          :class="[blok.items_classes, itemBlok.width]"
        >
          <div class="features-number-content">
            <div class="features-no-grid-item">
              <div class="features-no-content">
                <StoryblokComponent
                  :blok="{
                    ...itemBlok,
                    ...itemProperties,
                    ...utils.lottieProps(blok, true),
                  }"
                  :class="[blok.items_classes, itemBlok.width]"
                />
              </div>
            </div>
          </div>
          <div v-if="i + 1 < blok.items.length" class="features-number-content">
            <div class="features-no-grid-line"></div>
          </div>
        </template>
      </div>
      <div
        v-else-if="layout === 'features-2-grid'"
        class="w-layout-grid features-2-grid"
      >
        <StoryblokComponent
          v-for="(itemBlok, i) in blok.items"
          :key="itemBlok._uid || i"
          :blok="{
            ...itemBlok,
            ...itemProperties,
            ...utils.lottieProps(blok, true),
          }"
          class="features-2-grid-content"
          :class="[blok.items_classes, itemBlok.width]"
        />
        <slot />
      </div>
      <template v-else>
        <StoryblokComponent
          v-for="(itemBlok, i) in blok.items"
          :key="itemBlok._uid || i"
          :blok="{
            ...itemBlok,
            ...itemProperties,
            ...utils.lottieProps(blok, true),
          }"
          :class="[blok.items_classes, itemBlok.width]"
        />
        <slot />

        <div
          class="md:grid-cols-2 md:grid-cols-3 md:grid-cols-4 md:grid-cols-6 md:grid-cols-8 md:grid-cols-10 md:grid-cols-12 gap-4 gap-5 gap-12 w-2/5 w-3/5 w-36 w-52 w-56 w-48 w-64 flex-auto flex-col items-stretch md:flex-nowrap hidden"
        ></div>
        <div class="divide-x divide-x-2 divide-y divide-y-2 hidden"></div>
      </template>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  blok: Object,
  columns: Number,
});
const utils = useUtils();

const layout = computed(() => props.blok.layout || "feature-numbers-grid");

const cols = computed(() => props.columns || parseInt(props.blok.columns));
const classes = computed(
  () => `mx-auto
  ${props.blok.flexed ? "flex" : "grid"}
  ${props.blok.classes}
  ${props.blok.stretch_items ? "items-stretch" : ""}
  ${!props.blok.full_width ? "container " : ""}
  ${
  cols.value === 6
    ? "md:grid-cols-3 lg:grid-cols-6"
    : `md:grid-cols-${cols.value}`
}
  gap-${props.blok.gap || 12}
  place-items-center
  ${props.blok.divide_x ? `divide-x${props.blok.divide_x > 1 ? `-${props.blok.divide_x}` : ""}` : ""}
  ${props.blok.divide_y ? `divide-y${props.blok.divide_y > 1 ? `-${props.blok.divide_y}` : ""}` : ""}
  ${props.blok.divide_color ? `divide-${props.blok.divide_color === "primary" ? "[#a2027b]" : props.blok.divide_color}` : ""}
  `,
);

const itemProperties = computed(() => {
  return {
    icon_size: props.blok.icon_size,
    centered: props.blok.centered,
    icon_classes: props.blok.icon_classes,
    icon_wrapped: props.blok.icon_wrapped,
    headline_classes:
      layout.value === "features-2-grid" ? "features-item-title" : "",
  };
});
const numbersGridStyle = computed(() => {
  const itemCount = props.blok.items.length;
  let gridTemplate = "";

  for (let i = 0; i < itemCount; i++) {
    gridTemplate += "1fr ";
    if (i < itemCount - 1) {
      gridTemplate += "1px ";
    }
  }

  return gridTemplate.trim();
});

const gridStyle = computed(() => {
  const itemCount = props.blok.columns;
  let gridTemplate = "";

  for (let i = 0; i < itemCount; i++) {
    gridTemplate += "1fr ";
  }

  return gridTemplate.trim();
});
</script>

<style scoped>
.features-number-grid {
  grid-template-columns: v-bind(numbersGridStyle);
}
.features-2-grid {
  grid-template-columns: v-bind(gridStyle);
}
</style>
